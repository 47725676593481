.container {
  .element1 {
    position: fixed;
    width: 275px;
    height: 255px;
    top: 41%;
    left: 5%;
    background-image: url("../images/element1.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .element2 {
    position: fixed;
    width: 170px;
    height: 170px;
    top: 65%;
    left: 30%;
    background-image: url("../images/element2.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .element3 {
    position: fixed;
    width: 110px;
    height: 110px;
    top: 45%;
    left: 55%;
    background-image: url("../images/element3.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .element4 {
    position: fixed;
    width: 210px;
    height: 190px;
    top: 15%;
    right: 5%;
    background-image: url("../images/element4.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .element5 {
    position: fixed;
    width: 120px;
    height: 120px;
    top: 70%;
    right: 15%;
    background-image: url("../images/element5.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
