@import 'src/assets/scss/colors';

.layout {
    width: 100%;
    position: relative;
    min-height: 100vh !important;
}

.header {
    width: 100%;
    background-color: $white !important;
    box-shadow: 0px 1px 4px rgba($dark, 0.1);
    position: fixed;
    z-index: 2;
}

.content {
    padding-top: 65px;
    width: 100%;
    z-index: 1;
}
