@import "./font";

.d-flex {
    display: flex;
}

.justify-content-start {
    justify-content: flex-start;
}
.justify-content-end {
    justify-content: flex-end;
}

.justify-content-center {
    justify-content: center;
}
.justify-content-between {
    justify-content: space-between;
}

.ant-btn-primary {
    //background-color: red !important;
}