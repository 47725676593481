@import 'src/assets/scss/colors';

.container {
    top: 0;
    left: 0;
    z-index: 4;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
}
